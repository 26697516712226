import Head from 'next/head'
import { useRouter } from 'next/router'

const Meta = ({
	title,
	description,
	image,
	canonical,
}: {
	title: string
	description: string
	image?: string
	canonical?: string
}) => {
	const router = useRouter()
	const canonicalTag = (canonical || process.env.NEXT_PUBLIC_BASE_URL) + router.asPath

	return (
		<Head>
			<title>{title}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={`${canonicalTag}`} />
			{/* og */}
			<meta property='og:type' content='website' />
			<meta property='og:title' content={title} />
			<meta property='og:description' content={description} />
			<meta property='og:site_name' content='Hide and Seek World' />
			<meta
				property='og:image'
				content={`${process.env.NEXT_PUBLIC_BASE_URL + (image || 'images/thumbnail.jpg')}`}
			/>
			<meta property='og:url' content={`${canonicalTag}`} />
			<meta property='og:image:width' content='1200' />
			<meta property='og:image:height' content='627' />
			<meta property='fb:app_id' content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID} />
			{/* twitter */}
			<meta name='twitter:card' content='summary' />
			<meta name='twitter:creator' content='@hide_seek_world' />
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />
			{/* <meta name="twitter:site" content="@propernounco" /> */}
			{/* <meta name="twitter:creator" content="@IKreisz" /> */}
			<meta
				name='twitter:image:src'
				content={`${
					process.env.NEXT_PUBLIC_BASE_URL + (image || 'images/thumbnailTwitter.jpg')
				}`}
			/>
			<meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
			<meta name='viewport' content='width=device-width, initial-scale=1.0' />
		</Head>
	)
}
export default Meta
