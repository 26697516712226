import Modal from './Modal'
import { memo } from 'react'

const ErrorModal = ({
	error,
	clicked,
	title,
}: {
	error?: string
	clicked: () => void
	title?: string
}) => {
	return (
		<Modal
			isActive={!!error}
			clicked={clicked}
			title={title || 'Oh-oh'}
			message={error || 'Something went wrong'}
			close={clicked}></Modal>
	)
}

export default memo(ErrorModal)
