import { useState, useEffect, useContext, memo } from 'react'
import UserContext from '../../lib/context/UserContext'
import { useRouter } from 'next/router'
import Link from 'next/link'
import useAuthenticator from '../../lib/hooks/useAuthenticator'
import Logo from './Logo'
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion'
import Modal from './Modal'
import ErrorModal from './ErrorModal'
import IosGameLink from '../../pages-lib/index/IosGameLink'

const Header = ({
	page,
	subpage,
}: {
	page: 'landing' | 'profile' | 'gameinfo'
	subpage?: 'dashboard' | 'membership' | 'settings'
}) => {
	const { userContext } = useContext(UserContext)

	const router = useRouter()
	const [isOpen, setIsOpen] = useState(false)
	const [top, setTop] = useState(true)

	const [emailNotVerifiedErrorShown, setEmailNotVerifiedErrorShown] = useState(false)
	const { sendVerificationEmail, isEmailVerified, signOut } = useAuthenticator()
	const [alertMessage, setAlertMessage] = useState<
		{ title: string; message: string } | undefined
	>()

	const sendVerificationEmailClicked = async () => {
		setEmailNotVerifiedErrorShown(false)
		try {
			await sendVerificationEmail()
			setAlertMessage({
				title: 'Verification Email Sent',
				message:
					"Please check your inbox. Make sure to check your spam folder if it doesn't show up.",
			})
		} catch (err) {
			setAlertMessage({ title: 'Error', message: err.message || 'Unknown Error' })
		}
	}

	// detect whether user has scrolled the page down by 10px
	useEffect(() => {
		const scrollHandler = () => {
			window.pageYOffset > 10 ? setTop(false) : setTop(true)
		}
		window.addEventListener('scroll', scrollHandler)
		return () => window.removeEventListener('scroll', scrollHandler)
	}, [top])

	const [buttonTitles, setButtonTitles] = useState({
		first: '',
		second: '',
	})

	const navigateTo = (path) => {
		router.push(path)
	}

	const navigation = [
		{
			name: 'Dashboard',
			href: '/account/dashboard',
			current: subpage === 'dashboard' ? true : false,
		},
		{
			name: 'Membership',
			href: '/account/membership',
			current: subpage === 'membership' ? true : false,
		},
		{
			name: 'Settings',
			href: '/account/settings',
			current: subpage === 'settings' ? true : false,
		},
	]
	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(' ')
	}

	const firstButtonClicked = () => {
		if (userContext?.id) {
			signOut()
			navigateTo('/')
		} else {
			navigateTo('/signup')
		}
	}

	const secondButtonClicked = () => {
		if (userContext?.id) {
			if (page === 'landing' || page === 'gameinfo') {
				navigateTo('/account/dashboard')
			} else {
				if (!isEmailVerified()) {
					setEmailNotVerifiedErrorShown(true)
				} else navigateTo('/gamesetup')
			}
		} else {
			navigateTo('/login')
		}
	}

	useEffect(() => {
		if (typeof userContext !== 'undefined') {
			if (page === 'landing' || page === 'gameinfo') {
				setButtonTitles({
					first: userContext?.id ? 'LOGOUT' : 'SIGNUP',
					second: userContext?.id ? 'ACCOUNT' : 'LOGIN',
				})
			} else if (page === 'profile') {
				setButtonTitles({
					first: userContext?.id ? 'LOGOUT' : 'SIGNUP',
					second: userContext?.id ? 'PLAY' : 'LOGIN',
				})
			}
		}
	}, [userContext])

	return (
		<div>
			<ErrorModal
				title={alertMessage?.title}
				error={alertMessage?.message}
				clicked={setAlertMessage.bind(null, null)}></ErrorModal>
			<Modal
				isActive={emailNotVerifiedErrorShown}
				firstButtonClicked={setEmailNotVerifiedErrorShown.bind(null, false)}
				close={setEmailNotVerifiedErrorShown.bind(null, false)}
				firstButtonTitle='Close'
				secondButtonClicked={sendVerificationEmailClicked}
				secondButtonTitle={'Resend'}
				title='Please Confirm Email'
				message="Confirm your email by clicking the link in the email that was just sent. If you didn't receive the verification email, please check your spam folder or resend it."></Modal>
			<nav
				className={`fixed w-full z-50 transition duration-300 ease-in-out ${
					!top && 'shadow-lg'
				} ${
					top && page === 'landing'
						? 'bg-transparent'
						: page === 'landing' || page === 'gameinfo'
						? 'bg-gradient-to-r from-[#0063AA] via-darkblue to-[#0063AA]'
						: 'bg-darkerblue'
				}`}>
				<div className='max-w-screen-2xl mx-auto sm:px-5 lg:px-6 flex flex-col'>
					<div className='flex items-center justify-between h-14'>
						<div className='flex items-center'>
							<div className='shrink-0'>
								<Logo title={'Hide & Seek World'} hideIcon={true}></Logo>
							</div>
							{page === 'landing' && (
								<>
									{/*}
									<div className='dropdown dropdown-hover'>
										<div className='badge badge-lg border-1 text-xs sm:text-sm leading-3 border-gray-100 text-gray-100 bg-gray-100/10 font-medium mx-3 h-7 hover:bg-darkblue'>
											WHAT'S NEW?
										</div>
										<ul
											tabIndex={0}
											style={{ listStyleType: 'square' }}
											className='mt-3 ml-3 p-4 text-sm shadow menu dropdown-content bg-base-100 rounded-box w-72 gap-y-1'>
											<h4 className='font-bold text-base'>Recent Changes</h4>
											<div className='badge-xs badge-accent w-fit h-fit rounded-lg px-1 py-[2px]'>
												Nov 2022
											</div>
											<li>• New King of the World game mode!</li>
											<li>• Redesigned home page</li>
											<li>• No move & No move, no zoom</li>
											<li>• Can join games mid-game now</li>

											<div className='badge-xs badge-accent w-fit h-fit rounded-lg px-1 py-[2px]'>
												Oct 2022
											</div>
											<li>• Public profiles!</li>
											<li>
												• Start of King of the World game mode beta testing
											</li>
											<div className='badge-xs badge-accent w-fit h-fit rounded-lg px-1 py-[2px]'>
												Aug 2022
											</div>
											<li>• Out of BETA! 🥳</li>
											<li>• Legendary Geo Spirits</li>
											<li>• Annual Memberships</li>
											<li>• Many bug fixes and improvements</li>
											<div className='badge-xs badge-accent w-fit h-fit rounded-lg px-1 py-[2px]'>
												July 2022
											</div>
											<li>• Streamer mode</li>
											<li>• Return to lobby</li>
											<li>• Improved scoring system</li>
											<li>• No pan & no zoom & no move</li>
											<div className='badge-xs badge-accent w-fit h-fit rounded-lg px-1 py-[2px]'>
												June 2022
											</div>
											<li>• More in-game animations</li>
											<li>• Infographics for game modes</li>
											<li>• Watch Seekers in Private Match!</li>
										</ul>
									</div>*/}

									<div className='hidden lg:flex mx-3'>
										<IosGameLink />
									</div>
								</>
							)}

							<div className='hidden lg:block'>
								<div
									className={`ml-10 items-baseline space-x-4  ${
										page === 'profile' ? 'flex' : 'hidden'
									}`}>
									{navigation.map((item) => (
										<Link
											key={item.name}
											aria-current={item.current ? 'page' : undefined}
											href={item.href}
											className={classNames(
												item.current
													? 'bg-aquablue text-white'
													: 'text-gray-300 hover:bg-white hover:bg-opacity-10 hover:text-white',
												'px-3 py-2 rounded-md text-sm font-medium'
											)}>
											{item.name}
										</Link>
									))}
								</div>
							</div>
						</div>
						{/* Socials */}
						<div className='flex justify-center mr-auto ml-4 sm:ml-auto sm:mr-0 pr-5 align-middle'>
							{/* <a
								href='#'
								className='mx-1 text-gray-200  hover:text-white'
								aria-label='Twitter'>
								<svg
									className='w-6 h-6 fill-current'
									xmlns='http://www.w3.org/2000/svg'>
									<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z'></path>
								</svg>
							</a>
							<a
								href='#'
								className='mx-1 text-gray-200  hover:text-white'
								aria-label='Instagram'>
								<svg
									className='w-6 h-6 fill-current'
									xmlns='http://www.w3.org/2000/svg'>
									<path d='M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z'></path>
								</svg>
							</a> */}
							<a
								href='https://discord.gg/uCN465Ehc9'
								className='mx-0 sm:mx-1 text-gray-200  hover:text-white flex items-center'
								aria-label='Discord'>
								<h2 className='pr-2 font-display font-semibold sm:text-lg text-gray-100 hidden sm:block'>
									Join us on Discord!
								</h2>
								<svg
									className='w-6 h-6 fill-current'
									xmlns='http://www.w3.org/2000/svg'
									fillRule='evenodd'
									clipRule='evenodd'>
									<path d='M12 0c-6.626 0-12 5.372-12 12 0 6.627 5.374 12 12 12 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12zm3.248 18.348l-.371-1.295.896.833.847.784 1.505 1.33v-12.558c0-.798-.644-1.442-1.435-1.442h-9.38c-.791 0-1.435.644-1.435 1.442v9.464c0 .798.644 1.442 1.435 1.442h7.938zm-1.26-3.206l-.462-.567c.917-.259 1.267-.833 1.267-.833-.287.189-.56.322-.805.413-.35.147-.686.245-1.015.301-.672.126-1.288.091-1.813-.007-.399-.077-.742-.189-1.029-.301-.161-.063-.336-.14-.511-.238l-.028-.016-.007-.003-.028-.016-.028-.021-.196-.119s.336.56 1.225.826l-.469.581c-1.547-.049-2.135-1.064-2.135-1.064 0-2.254 1.008-4.081 1.008-4.081 1.008-.756 1.967-.735 1.967-.735l.07.084c-1.26.364-1.841.917-1.841.917l.413-.203c.749-.329 1.344-.42 1.589-.441l.119-.014c.427-.056.91-.07 1.414-.014.665.077 1.379.273 2.107.672 0 0-.553-.525-1.743-.889l.098-.112s.959-.021 1.967.735c0 0 1.008 1.827 1.008 4.081 0 0-.573.977-2.142 1.064zm-.7-3.269c-.399 0-.714.35-.714.777 0 .427.322.777.714.777.399 0 .714-.35.714-.777 0-.427-.315-.777-.714-.777zm-2.555 0c-.399 0-.714.35-.714.777 0 .427.322.777.714.777.399 0 .714-.35.714-.777.007-.427-.315-.777-.714-.777z'></path>
								</svg>
							</a>
						</div>
						{/* Site Auth */}

						<div className='hidden lg:block'>
							<ul className='flex flex-shrink justify-end flex-wrap items-center'>
								<li>
									<div>
										<button
											onClick={firstButtonClicked}
											className={`btn h-8 w-14 sm:w-20 flex-shrink rounded-3xl filter drop-shadow-3xl ${
												buttonTitles.first === 'LOGOUT'
													? 'bg-medblue/30 border-gray-400 border-solid border-opacity-100'
													: 'btn-secondary'
											}`}>
											{buttonTitles.first}
										</button>
									</div>
								</li>
								<li>
									<div>
										<button
											onClick={secondButtonClicked}
											className='btn h-8 w-14 sm:w-20 btn-primary ml-2 flex-shrink rounded-3xl filter drop-shadow-3xl '>
											{buttonTitles.second}
										</button>
									</div>
								</li>
							</ul>
						</div>

						<div className='mr-2 flex lg:hidden'>
							<button
								onClick={() => setIsOpen(!isOpen)}
								type='button'
								className='bg-darkerblue2 bg-opacity-50 inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-white hover:bg-darkerblue2 focus:outline-none focus:border-2 focus:border-white'
								aria-controls='mobile-menu'
								aria-expanded='false'>
								<span className='sr-only'>Open main menu</span>
								{!isOpen ? (
									<svg
										className='block h-6 w-6'
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
										aria-hidden='true'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M4 6h16M4 12h16M4 18h16'
										/>
									</svg>
								) : (
									<svg
										className='block h-6 w-6'
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
										aria-hidden='true'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M6 18L18 6M6 6l12 12'
										/>
									</svg>
								)}
							</button>
						</div>
					</div>
				</div>

				{/* Hamburger Menu */}
				<AnimatePresence exitBeforeEnter={false}>
					<LazyMotion features={domAnimation}>
						{isOpen && (
							<m.div
								initial={{
									opacity: 0,
									scale: 0.95,
								}}
								animate={{
									opacity: 1,
									scale: 1,
								}}
								exit={{
									opacity: 0,
									scale: 0.95,
								}}
								transition={{ duration: 0.3 }}>
								<div
									className='lg:hidden bg-darkerblue2 px-2 pt-2 pb-3 space-y-1 sm:px-3'
									id='mobile-menu'>
									<div
										className={
											page === 'profile' ? 'flex flex-col gap-y-2' : 'hidden'
										}>
										<Link
											aria-label='Home'
											className='text-gray-300 hover:bg-white hover:bg-opacity-10 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
											href='/'>
											Home
										</Link>
										{navigation.map((item) => (
											<Link
												className={classNames(
													item.current
														? 'bg-aquablue text-white '
														: 'text-gray-300 hover:bg-white hover:bg-opacity-10 hover:text-white',
													'px-3 py-2 rounded-md text-sm font-medium'
												)}
												href={item.href}
												key={item.href}
												aria-current={item.current ? 'page' : undefined}>
												{item.name}
											</Link>
										))}
									</div>

									<div className='pb-4 flex flex-col ml-2 pt-3 gap-y-5'>
										<button
											onClick={secondButtonClicked}
											className='btn h-9 w-20 btn-primary flex-shrink rounded-3xl filter drop-shadow-3xl '>
											{buttonTitles.second}
										</button>
										<button
											onClick={firstButtonClicked}
											className={`btn h-9 w-20 flex-shrink rounded-3xl filter drop-shadow-3xl ${
												buttonTitles.first === 'LOGOUT'
													? 'bg-darkerblue  border-gray-400 border-solid border-opacity-100'
													: 'btn-secondary '
											}`}>
											{buttonTitles.first}
										</button>
									</div>
								</div>
							</m.div>
						)}
					</LazyMotion>
				</AnimatePresence>
			</nav>
		</div>
	)
}

export default memo(Header)
