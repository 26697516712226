import Link from 'next/link'
import Image from 'next/image'

const Logo = ({ hideIcon, title }: { hideIcon?: boolean; title: string }) => {
	return (
		<Link className='flex justify-center self-center' aria-label='Home' href='/'>
			<Image
				src='/images/wolly-logo.svg'
				alt='Wolly Logo'
				height={48}
				width={48}
				className={`h-16 sm:h-8 mr-3 ${hideIcon === true ? 'hidden' : ''}`}
				priority={true}></Image>
			<div className='text-2xl text-gray-100 font-display font-semibold align-middle hidden sm:block shadowtext mb-1'>
				{title}
			</div>
		</Link>
	)
}

export default Logo
