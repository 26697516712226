import React from 'react'

const iosGameLink = () => {
	return (
		<a
			href='https://apps.apple.com/app/id1639815465'
			className='text-gray-100 bg-opacity-80 font-medium h-7 bg-gray-100/10 badge badge-lg border-1 border-white text-sm leading-3 hover:bg-darkblue mt-14 lg:mt-0'>
			World <span className='text-orange mx-1 italic'> Streaks</span> on iOS
		</a>
	)
}

export default React.memo(iosGameLink)
